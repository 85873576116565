<template>
  <div
    class="grid"
    style="
      box-sizing: border-box;
      background: #ffffff;
      border: 2px solid #f5f6f7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
      justify-content: center;
      padding-bottom: 30px;
    "
  >
    <div v-if="chartType === 'microorganisms'" class="col-12">
      <div class="grid" style="justify-content: center; align-items: start">
        <div
          class="col-10 flex"
          style="justify-content: center; align-items: start"
        >
          <Image
            src="/images/bacteria.svg"
            alt="Image"
            width="30"
            style="padding-right: 10px; padding-top: 15px"
          />
          <h5
            style="
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              color: #466107;
              display: flex;
              align-items: center;
            "
          >
            Bactérias
          </h5>
        </div>
        <div class="col-2">
          <SelectButton
            v-model="graph"
            :options="GraphOptions"
            aria-labelledby="single"
          />
        </div>
      </div>
    </div>
    <div v-else-if="chartType === 'basic'" class="col-12">
      <p
        style="
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
          color: #585857;
          padding-top: 25px;
        "
      >
        Micro-organismos correlacionados com o aumento de
        {{ description.organism }}
      </p>
            <p style="font-size:11px; color: #585857">Neste gráfico, são apresentados apenas micro-organismos com diferenças significativas à 5% de probabilidade entre o controle e produto.</p>

      <div
        class="col-12 flex"
        style="justify-content: center; align-items: center"
      >
        <Image
          src="/images/locIcon.svg"
          alt="Image"
          width="30"
          style="padding-right: 10px; padding-top: 10px"
        />
        <h5
          style="
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #466107;
            display: flex;
            align-items: center;
            padding-right: 20px;
          "
        >
          Todas as Áreas
        </h5>
        <Image
          src="/images/logoVeg.svg"
          alt="Image"
          width="30"
          style="padding-right: 10px; padding-top: 10px"
        />
        <h5
          style="
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #466107;
            display: flex;
            align-items: center;
          "
        >
          Vegetativo
        </h5>
      </div>
    </div>

    <div v-else-if="chartType === 'pathogenic'" class="col-12">
      <div
        class="col-12 flex"
        style="justify-content: center; align-items: center"
      >
        <Image
          src="/images/locIcon.svg"
          alt="Image"
          width="30"
          style="padding-right: 10px; padding-top: 10px"
        />
        <h5
          style="
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #466107;
            display: flex;
            align-items: center;
            padding-right: 20px;
          "
        >
          Todas as Áreas
        </h5>
        <Image
          src="/images/logoVeg.svg"
          alt="Image"
          width="30"
          style="padding-right: 10px; padding-top: 10px"
        />
        <h5
          style="
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #466107;
            display: flex;
            align-items: center;
          "
        >
          Vegetativo
        </h5>
      </div>
    </div>

    <div v-else style="padding: 20px" class="col-12">
      <span></span>
    </div>
    <div
      class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
    >
      <span v-if="chartType === 'microorganisms'" class="titleChart"
        >Tratamento</span
      >
      <span
        v-else-if="chartType === 'basic' || chartType === 'pathogenic'"
        class="titleChart"
        >Bactérias</span
      >

      <div v-if="data[description.organism][chartType === 'microorganisms' ? 'control' : 'bacterias'].length === 0">
        <Chip
          style="margin-top: 60px; margin-left: 40px"
          label="Não foram encontrados organismos com significância estatística"
          class="custom-chip"
        />
      </div>
      <div v-else>
        <div v-if="chartType === 'microorganisms'">
          <DxChart
            id="chart"
            :data-source="data[description.organism].control"
            :rotated="true"
            default-pane="bottom"
            :palette="['#95AF58', '#415C02', '#777777', '#777777', '#777777']"
          >
            <DxCommonSeriesSettings argument-field="type" type="bar" />
            <DxSeries
              value-field="avgT"
              type="bar"
              :name="chartType === 'microorganisms' ? 'Vegetativo' : 'Controle'"
            >
              <DxValueErrorBar :value="0.01" :line-width="1" type="fixed" />
            </DxSeries>
            <DxSeries
              value-field="avgH"
              type="bar"
              :name="
                chartType === 'microorganisms' ? 'Florescimento' : 'Produto'
              "
            >
              <DxValueErrorBar :value="0.03" :line-width="1" type="fixed" />
            </DxSeries>

            <DxArgumentAxis>
              <DxLabel display-mode="stagger" />
            </DxArgumentAxis>

            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
            />
            <DxExport :enabled="false" />
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
          </DxChart>
        </div>
        <div v-else>
          <DxChart
            id="chart"
            :data-source="data[description.organism].bacterias"
            :rotated="true"
            default-pane="bottom"
            :palette="['#95AF58', '#415C02', '#777777', '#777777', '#777777']"
          >
            <DxCommonSeriesSettings argument-field="type" type="bar" />
            <DxSeries
              value-field="avgT"
              type="bar"
              :name="chartType === 'microorganisms' ? 'Vegetativo' : 'Controle'"
            >
              <DxValueErrorBar :value="0.0001" :line-width="1" type="fixed" />
            </DxSeries>
            <DxSeries
              value-field="avgH"
              type="bar"
              :name="
                chartType === 'microorganisms' ? 'Florescimento' : 'Produto'
              "
            >
              <DxValueErrorBar :value="0.003" :line-width="1" type="fixed" />
            </DxSeries>

            <DxArgumentAxis>
              <DxLabel display-mode="stagger" />
            </DxArgumentAxis>

            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
            />
            <DxExport :enabled="false" />
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
          </DxChart>
        </div>
        <div style="text-align: end; margin-top: -55px">
          <span style="font-size: 12px; font-weight: 600">Frequência (%) </span>
        </div>
      </div>
    </div>
    <div
      class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
      style="padding-left: 45px"
    >
      <span v-if="chartType === 'microorganisms'" class="titleChart"
        >Controle</span
      >
      <span
        v-else-if="chartType === 'basic' || chartType === 'pathogenic'"
        class="titleChart"
        >Fungos</span
      >
      <div v-if="data[description.organism][chartType === 'microorganisms' ? 'treatment' : 'fungus'].length === 0">
        <Chip
          style="margin-top: 60px; margin-left: 40px"
          label="Não foram encontrados organismos com significância estatística"
          class="custom-chip"
        />
      </div>
      <div v-else>
        <div v-if="chartType === 'microorganisms'">
          <DxChart
            id="chart"
            :data-source="data[description.organism].treatment"
            :rotated="true"
            default-pane="bottom"
            :palette="['#95AF58', '#415C02', '#777777', '#777777', '#777777']"
          >
            <DxCommonSeriesSettings argument-field="type" type="bar" />
            <DxSeries
              value-field="avgT"
              type="bar"
              :name="chartType === 'microorganisms' ? 'Vegetativo' : 'Controle'"
            >
              <DxValueErrorBar :value="0.001" :line-width="1" type="fixed" />
            </DxSeries>
            <DxSeries
              value-field="avgH"
              type="bar"
              :name="
                chartType === 'microorganisms' ? 'Florescimento' : 'Produto'
              "
            >
              <DxValueErrorBar :value="0.003" :line-width="1" type="fixed" />
            </DxSeries>

            <DxArgumentAxis>
              <DxLabel display-mode="stagger" />
            </DxArgumentAxis>

            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
            />
            <DxExport :enabled="false" />
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
          </DxChart>
        </div>
        <div v-else>
          <DxChart
            id="chart"
            :data-source="data[description.organism].fungus"
            :rotated="true"
            default-pane="bottom"
            :palette="['#95AF58', '#415C02', '#777777', '#777777', '#777777']"
          >
            <DxCommonSeriesSettings argument-field="type" type="bar" />

            <DxSeries
              value-field="avgT"
              type="bar"
              :name="chartType === 'microorganisms' ? 'Vegetativo' : 'Controle'"
            >
              <DxValueErrorBar :value="0.01" :line-width="1" type="fixed" />
            </DxSeries>
            <DxSeries
              value-field="avgH"
              type="bar"
              :name="
                chartType === 'microorganisms' ? 'Florescimento' : 'Produto'
              "
            >
              <DxValueErrorBar :value="0.01" :line-width="1" type="fixed" />
            </DxSeries>

            <DxArgumentAxis>
              <DxLabel display-mode="stagger" />
            </DxArgumentAxis>

            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
            />
            <DxExport :enabled="false" />
            <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
          </DxChart>
        </div>
        <div style="text-align: end; margin-top: -55px">
          <span style="font-size: 12px; font-weight: 600">Frequência (%) </span>
        </div>
      </div>
    </div>
    <div v-if="this.filterType === 'basic'">
      <div
        class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
        v-if="project.typeProject === '01'"
      >
        <Button label="Filtrar" icon="pi pi-filter" @click="openModal" />
        <Filter
          :options="filterBasicFull"
          @change="(f) => (filter = f)"
          ref="filterEl"
        />
      </div>

      <div
        v-else-if="project.typeProject === '02'"
        class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
      >
        <Button label="Filtrar" icon="pi pi-filter" @click="openModal" />
        <Filter
          :options="filterBasicLimited"
          @change="(f) => (filter = f)"
          ref="filterEl"
        />
      </div>
    </div>
    <div v-else-if="this.filterType === 'betaDiversity'">
      <div
        class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
        v-if="project.typeProject === '01'"
      >
        <Button label="Filtrar" icon="pi pi-filter" @click="openModal" />
        <Filter
          :options="filterBetaDiversityFull"
          @change="(f) => (filter = f)"
          ref="filterEl"
        />
      </div>

      <div
        v-else-if="project.typeProject === '02'"
        class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
      >
        <Button label="Filtrar" icon="pi pi-filter" @click="openModal" />
        <Filter
          :options="filterBetaDiversityLimited"
          @change="(f) => (filter = f)"
          ref="filterEl"
        />
      </div>
    </div>

    <div v-else-if="this.filterType === 'none'"></div>

    <div v-else class="col-6 xl:col-12 md:col-12 lg:col-12 sm:col-12">
      <span>Filtro não suportado para seu tipo de projeto</span>
    </div>
  </div>
</template>

<script>
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxValueErrorBar,
  DxArgumentAxis,
  DxExport,
  DxLegend,
  DxLabel,
  DxTooltip,
} from "devextreme-vue/chart";
import { useProjectsStore } from "@/store/projects";
import Filter from "@/components/global/Filter.vue";

export default {
  data() {
    return {
      filter: null,
      graph: "Bactérias",
      GraphOptions: ["Bactérias", "Fungos"],
      filterBasicFull: {
        items: [
          {
            title: "Selecione o grupo taxonômico que deseja avaliar.",
            value: "taxonomico",
            options: [
              {
                label: "Controle X Produto",
                value: "0",
                image: "/images/CPIcon.svg",
                checked: true,
              },
              {
                label: "Vegetativo x Reprodutivo",
                value: "1",
                image: "/images/VFIcon.svg",
              },
            ],
          },
          {
            title: "Selecione a área.",
            value: "area",
            options: [
              {
                label: "Todas as Áreas",
                value: "0",
                checked: true,
              },
              {
                label: "Área 1",
                value: "1",
              },
              {
                label: "Área 2",
                value: "2",
              },
              {
                label: "Área 3",
                value: "3",
              },
            ],
          },

          {
            condition: (filter) => filter.taxonomico === "0",
            title: "Selecione o produto.",
            value: "estagio",
            options: [
              {
                label: "Vegetativo",
                value: "0",
                image: "/images/logoVeg.svg",
                checked: true,
              },
              {
                label: "Reprodutivo",
                value: "1",
                image: "/images/florIcon.svg",
              },
            ],
          },

          {
            condition: (filter) => filter.taxonomico != "0",
            title: "Selecione o Produto.",
            value: "produto",
            options: [
              {
                label: "Controle",
                value: "0",
                image: "/images/control.svg",
                checked: true,
              },
              {
                label: "Produto",
                value: "1",
                image: "/images/product.svg",
              },
            ],
          },
        ],
      },

      filterBasicLimited: {
        items: [
          {
            title: "Selecione o grupo taxonômico que deseja avaliar.",
            value: "taxonomico",
            options: [
              {
                label: "Controle X Produto",
                value: "0",
                image: "/images/CPIcon.svg",
                checked: true,
              },
            ],
          },
          {
            title: "Selecione a área.",
            value: "area",
            options: [
              {
                label: "Todas as Áreas",
                value: "0",
                checked: true,
              },
              {
                label: "Área 1",
                value: "1",
              },
              {
                label: "Área 2",
                value: "2",
              },
              {
                label: "Área 3",
                value: "3",
              },
            ],
          },

          {
            title: "Selecione o produto.",
            value: "estagio",
            options: [
              {
                label: "Vegetativo",
                value: "0",
                image: "/images/logoVeg.svg",
                checked: true,
              },
            ],
          },
        ],
      },

      filterBetaDiversityFull: {
        items: [
          {
            title: "Selecione as variáveis que deseja avaliar no gráfico.",
            value: "taxonomico",
            options: [
              {
                label: "Bactérias",
                value: "0",
                image: "/images/bacteria.svg",
                checked: true,
              },
              {
                label: "Fungos",
                value: "1",
                image: "/images/fungus.svg",
              },
            ],
          },
          {
            title: "Selecione o estágio.",
            value: "estagio",
            options: [
              {
                label: "Vegetativo",
                value: "0",
                image: "/images/logoVeg.svg",
                checked: true,
              },
              {
                label: "Reprodutivo",
                value: "1",
                image: "/images/florIcon.svg",
              },
            ],
          },
        ],
      },

      filterBetaDiversityLimited: {
        items: [
          {
            title: "Selecione as variáveis que deseja avaliar no gráfico.",
            value: "taxonomico",
            options: [
              {
                label: "Bactérias",
                value: "0",
                image: "/images/bacteria.svg",
                checked: true,
              },
              {
                label: "Fungos",
                value: "1",
                image: "/images/fungus.svg",
              },
            ],
          },
          {
            title: "Selecione o estágio.",
            value: "estagio",
            options: [
              {
                label: "Vegetativo",
                value: "0",
                image: "/images/florIcon.svg",
                checked: true,
              },
            ],
          },
        ],
      },

      projectsStore: useProjectsStore(),
      VariableFilter1: "ControleProduto",
      VariableFilter2: "AllAreas",
      VariableFilter3: "Vegetativo",
      URLFilter: "",

      chartOptions: {
        palette: [
          "#7CBAB4",
          "#92C7E2",
          "#75B5D6",
          "#B78C9B",
          "#F2CA84",
          "#A7CA74",
        ],
      },

      selectedType: null,
      loading: true,
      displayModal: false,
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {
    this.verifyData();
    console.log(this.filterType);
  },
  watch: {
    filter: {
      handler: function () {
        this.$emit("filter", this.filter);
      },
      deep: true,
    },
  },
  beforeUnmount() {},
  created() {},
  methods: {
    verifyData() {
      if (this.project.typeProject != "01") {
        this.changes = this.changes2;
      } else {
        return this.changes;
      }
    },

    ChangeVariableFilter1() {
      this.VariableFilter1 = "ControleProduto";
      console.log(this.VariableFilter1);
    },
    ChangeVariableFilter2() {
      this.VariableFilter1 = "VegetativoFlorescimento";
      console.log(this.VariableFilter1);
    },
    ChangeVariableFilter3() {
      this.VariableFilter2 = "AllAreas";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter4() {
      this.VariableFilter2 = "Area1";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter5() {
      this.VariableFilter2 = "Area2";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter6() {
      this.VariableFilter2 = "Area3";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter7() {
      this.VariableFilter3 = "Vegetativo";
      console.log(this.VariableFilter3);
    },
    ChangeVariableFilter8() {
      this.VariableFilter3 = "Florescimento";
      console.log(this.VariableFilter3);
    },
    ChangeVariableFilter9() {
      this.VariableFilter3 = "Controle";
      console.log(this.VariableFilter3);
    },
    ChangeVariableFilter10() {
      this.VariableFilter3 = "Produto";
      console.log(this.VariableFilter3);
    },
    getFilter() {
      this.URLFilter =
        this.VariableFilter1 +
        ":" +
        this.VariableFilter2 +
        ":" +
        this.VariableFilter3;
      console.log(this.URLFilter);
    },

    attFake1() {
      this.weatherData = this.weatherData2;
      this.displayModal = false;
    },

    openModal() {
      this.$refs.filterEl.open();
    },
    closeModal() {
      this.displayModal = false;
    },
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.seriesName}: ${pointInfo.value} (Média: ${pointInfo.lowErrorValue} - ${pointInfo.highErrorValue})`,
      };
    },
  },
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxValueErrorBar,
    DxArgumentAxis,
    DxExport,
    DxLegend,
    DxLabel,
    DxTooltip,
    Filter,
  },
  props: {
    data: Object,
    description: Object,
    filterType: String,
    chartType: String,
  },
};
</script>

<style></style>
